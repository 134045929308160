import { MapsAPILoader } from "@agm/core";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../cooldash/services/api/api.service";
import { CommonService } from "../cooldash/services/common/common.service";

@Component({
  selector: "app-qr-page",
  templateUrl: "./qr-page.component.html",
  styleUrls: ["./qr-page.component.scss"],
})
export class QrPageComponent implements OnInit {
  id: any;
  foodItem: any;
  allData: any[];
  loader: boolean = true;
  imageUrl: string;
  restaurantDetail: any;

  constructor(
    public api: ApiService,
    private mapsAPILoader: MapsAPILoader,
    public comm: CommonService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.imageUrl = this.comm.imageUrl;
    // if (localStorage.getItem("restaurantLogin")) {
    //   var data = JSON.parse(localStorage.getItem("restaurantLogin"));
    //   if (data.id) {
    //     this.id = data.id;
    //     this.getFoodItem(this.id);
    //   }
    // }
    // if (localStorage.getItem("restaurantLogin")) {
    //   var data = JSON.parse(localStorage.getItem("restaurantLogin"));
    //   if (data.id) {
    //     this.id = data.id;
    //     this.getRestaurantById(this.id);
    //   }
    // }
    this.route.params.subscribe((params) => {
      console.log(params);
      this.id = params["id"];
      
      this.getRestaurantById(this.id);
      this.getFoodItem(this.id);
    });
  }

  getFoodItem(id) {
    this.api.getRestaurantItemeById(id).subscribe((res) => {
      console.log(res);
      if (res["response"]["success"]) {
        this.foodItem = res["data"];
        this.allData = this.foodItem;
        for (var data of this.foodItem) {
          data.status = data["status"] == 1 ? true : false;
        }
        this.loader = false;
      }
    });
  }
  getRestaurantById(id) {
    this.api.getResturantById(id).subscribe((res) => {
      if (res["response"]["success"]) {
        this.restaurantDetail = res["data"];
        console.log(this.restaurantDetail);
        //this.setValues(this.restaurantDetail);
      }
    });
  }
}
