// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAr7uhCkuyvMm_05PoyW4BcO_euLF-kcL0",
    authDomain: "fooddeliverychiddi.firebaseapp.com",
    projectId: "fooddeliverychiddi",
    storageBucket: "fooddeliverychiddi.appspot.com",
    messagingSenderId: "651199410534",
    appId: "1:651199410534:web:59ef7d6074e351f286d8a7",
    measurementId: "G-DYBK4SQ4XN",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
