import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from "../cooldash/services/api/api.service";
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  isSubmitted: boolean = false;
  email = new FormControl('',[Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'),Validators.required]);

  constructor(private api:ApiService) {}

  ngOnInit() {}

  close() {
    var data = {
      response: "no",
      email: this.email
    };
    // this.dialog.close(data);
  }

  onSubmit() {
    this.isSubmitted = true;
    if(this.email.valid && this.isSubmitted){
      var data = {
        response: "yes",
        email: this.email.value
      };
      this.api.forgotPasswordSendEmail(data).subscribe(res=>{
        console.log(res)
      })

      // this.dialog.close(data);
    }
    }
}

