import { Component } from "@angular/core";
import { MessagingService } from "./commonservice/messaging.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "push-notification";
  message;
  constructor(private messagingService: MessagingService) { }

  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
   }
}
 