import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { UrlService } from "../url/url.service";
import { LoginBody } from "../../requests/login-body";
import { map } from "rxjs/operators";
import { CommonService } from "../common/common.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  countryCode: any;

  constructor(
    private http: HttpClient,
    private url: UrlService,
    private comm: CommonService
  ) {
    this.getCountryCode();
  }
  header() {
    if (localStorage.token != undefined || localStorage.token != null) {
      const headers = new HttpHeaders({
        // 'cache-control': 'no-cache',
        // 'content-type': 'application/json',
        authorization: "AP" + " " + JSON.parse(localStorage.token),
      });
      const option = {
        headers,
      };
      return option;
    }
  }
  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map((response) => response));
  }

  getDashboardStats(data) {
    return this.http.post(
      this.comm.baseUrl + "/getDashboardStats",
      data,
      this.header()
    );
  }

  singIn(body) {
    return this.http.post(this.comm.baseUrl + "/panel/login", body);
  }

  forgotPasswordSendEmail(body) {
    return this.http.post(
      this.comm.baseUrlwitoutAdmin + "/restaurant/forgotPassword",
      body
    );
  }

  resetPassword(body) {
    let endPoint: string;
    if (body.resetToken) {
      endPoint = "forgotChangePassword";
    }
    if (body.verificationToken) {
      endPoint = "verifyEmailLink";
    }
    return this.http.post(
      this.comm.baseUrlwitoutAdmin + "/restaurant/" + endPoint,
      body,
      this.header()
    );
  }

  sendToken(token: string) {
    localStorage.setItem("token", token);
  }
  getToken() {
    return localStorage.getItem("token");
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  getAllRestaurantCategories(url) {
    return this.http.get(
      this.comm.baseUrl + "/food/" + url + "?categoryType=1",
      this.header()
    );
  }

  addFoodCategory(body) {
    return this.http.post(
      this.comm.baseUrl + "/food/category",
      body,
      this.header()
    );
  }

  editFoodCategory(body) {
    return this.http.put(
      this.comm.baseUrl + "/food/category",
      body,
      this.header()
    );
  }

  getAllRestaurant() {
    return this.http.get(this.comm.baseUrl + "/food/restaurant", this.header());
  }

  getCrm() {
    return this.http.get(this.comm.baseUrl + "/food/getCrm", this.header());
  }

  addCrm(body) {
    return this.http.post(
      this.comm.baseUrl + "/food/addCrm",
      body,
      this.header()
    );
  }

  editFoodRestaurant(body) {
    return this.http.put(
      this.comm.baseUrl + "/food/restaurant",
      body,
      this.header()
    );
  }

  getResturantById(id) {
    return this.http.get(
      this.comm.baseUrl + "/food/restaurant/" + id,
      this.header()
    );
  }

  getResturantFoodItemById(id) {
    return this.http.get(
      this.comm.baseUrl + "/food/itembyid/" + id,
      this.header()
    );
  }

  addFoodRestaurant(body) {
    return this.http.post(
      this.comm.baseUrl + "/food/restaurant",
      body,
      this.header()
    );
  }

  getAllOrders(page, id) {
    console.log("######", page, id);
    return this.http.get(
      this.comm.baseUrl +
        "/food/orders?page=" +
        page +
        "&restaurantId=" +
        id +
        "&categoryType=" +
        1,
      this.header()
    );
  }

  getPendingOrders(status, page, id) {
    console.log("Pending", page, id);
    return this.http.get(
      this.comm.baseUrl +
        "/food/orders?status=" +
        status +
        "&page=" +
        page +
        "&restaurantId=" +
        id +
        "&categoryType=" +
        1,
      this.header()
    );
  }

  getOutletById(id) {
    return this.http.get(
      this.comm.baseUrl + "/food/restaurant/outlet/" + id,
      this.header()
    );
  }

  addRestaurantOutlet(body) {
    return this.http.post(
      this.comm.baseUrl + "/food/restaurant/outlet",
      body,
      this.header()
    );
  }

  editRestaurantOutlet(body) {
    return this.http.put(
      this.comm.baseUrl + "/food/restaurant/outlet",
      body,
      this.header()
    );
  }

  getRestaurantTypeById(id) {
    return this.http.get(this.comm.baseUrl + "/food/type/" + id, this.header());
  }

  getRestaurantItemeById(id) {
    return this.http.get(this.comm.baseUrl + "/food/item/" + id, this.header());
  }

  addRestaurantFoodType(body) {
    return this.http.post(
      this.comm.baseUrl + "/food/type",
      body,
      this.header()
    );
  }

  addRestaurantFoodItem(body) {
    return this.http.post(
      this.comm.baseUrl + "/food/item",
      body,
      this.header()
    );
  }

  editRestaurantFoodType(body) {
    return this.http.put(this.comm.baseUrl + "/food/type", body, this.header());
  }

  editRestaurantFoodItem(body) {
    return this.http.put(this.comm.baseUrl + "/food/item", body, this.header());
  }
  getRestaurantOrderDetail(id) {
    return this.http.get(
      this.comm.baseUrl + "/food/order/" + id,
      this.header()
    );
  }

  changePassword(body) {
    return this.http.post(
      this.comm.baseUrl + "/panel/changepass",
      body,
      this.header()
    );
  }

  getAddOns(id) {
    return this.http.get(this.comm.baseUrl + "/getAddOns/" + id, this.header());
  }

  getAddOnById(id) {
    return this.http.get(
      this.comm.baseUrl + "/getAddOnById/" + id,
      this.header()
    );
  }

  addAddOn(body) {
    return this.http.post(this.comm.baseUrl + "/addOns", body, this.header());
  }

  deleteAddOn(id) {
    return this.http.delete(
      this.comm.baseUrl + "/deleteAddOns/" + id,
      this.header()
    );
  }

  activeInactiveAddOn(body) {
    return this.http.put(this.comm.baseUrl + "/addOns", body, this.header());
  }

  editAddOn(body) {
    return this.http.put(this.comm.baseUrl + "/addOns", body, this.header());
  }

  getAdminModules() {
    return this.http.get(
      this.comm.baseUrl + "/admin/getAdminModules",
      this.header()
    );
  }

  addSubAdmin(data) {
    return this.http.post(
      this.comm.baseUrl + "/admin/subAdmin",
      data,
      this.header()
    );
  }
  updateSubAdmin(data) {
    return this.http.put(
      this.comm.baseUrl + "/admin/subAdmin",
      data,
      this.header()
    );
  }
  getAllSubAdmin(data) {
    return this.http.get(
      this.comm.baseUrl + "/admin/subAdmin" + (data.id ? "/" + data.id : ""),
      data
    );
  }
  deleteSubAdmin(data) {
    return this.http.delete(this.comm.baseUrl + "/admin/subAdmin", data);
  }

  addAccessModules(data) {
    return this.http.post(
      this.comm.baseUrl + "/admin/accessModule",
      data,
      this.header()
    );
  }

  editAccessModules(data) {
    return this.http.put(
      this.comm.baseUrl + "/admin/accessModule",
      data,
      this.header()
    );
  }

  deleteAccessModules(param) {
    return this.http.delete(this.comm.baseUrl + "/admin/accessModule", param);
  }

  getAccessModuleId(id) {
    return this.http.get(
      this.comm.baseUrl + "/admin/getAccessModuleId/" + id,
      this.header()
    );
  }

  getAllAccessModules() {
    return this.http.get(
      this.comm.baseUrl + "/admin/accessModule",
      this.header()
    );
  }
}
